import axios from 'axios';

export const getCustomerList = () => axios.get('/customers/');

export const getCustomerById = (id) => axios.get(`/customers/${id}/`);

export const createCustomer = (data) => axios.post('/customers/', data);

export const updateCustomer = (id, data) => axios.patch(`/customers/${id}/`, data);

export const resetCustomerUserPassword = (id) => axios.post(`/customer_users/${id}/password_reset/`);

<template>
    <div class="card mb-3">
        <div class="card-header">
            <div class="row align-items-center justify-content-between">
                <div class="col-4 col-sm-auto">
                    <h5 class="fs-0 mb-0 text-nowrap py-2 py-sm-0">Customers</h5>
                </div>
                <div class="col-12 col-sm-9 col-md-8 col-lg-6 col-xxl-5 d-flex align-items-center">
                    <input type="text"
                           class="form-control form-control-sm"
                           placeholder="Search..."
                           aria-label="Search"
                           v-model="search">
                    <router-link :to="{ name: 'create-customer' }"
                                 class="btn btn-falcon-default btn-sm text-nowrap ml-2"
                                 v-if="$auth.hasPermissions('create:customers')">
                        <span class="fas fa-plus mr-1" data-fa-transform="shrink-3" />Add Customer
                    </router-link>
                </div>
            </div>
        </div>
        <div class="card-body p-0">
            <LoadingSpinner v-if="loading" class="my-5" />
            <LoadingError v-else-if="error" class="my-6" />
            <VTable v-else-if="customers.length"
                    :data="customers"
                    :filter="search"
                    :filter-fields="['identifier', 'name']"
                    :initial-sort="{ field: 'name', order: true }"
                    :page-size="30">
                <template #header="{ sort, sortBy }">
                    <tr>
                        <VTableHeader field="identifier" v-bind="{ sort, sortBy }">
                            ID
                        </VTableHeader>
                        <VTableHeader field="name" v-bind="{ sort, sortBy }">
                            Name
                        </VTableHeader>
                    </tr>
                </template>
                <template #row="{ item: customer }">
                    <tr :key="customer.id">
                        <th class="py-2 align-middle white-space-nowrap">
                            <router-link :to="{ name: 'customer-details', params: { id: customer.id }}">
                                <strong>{{ customer.identifier }}</strong>
                            </router-link>
                        </th>
                        <td class="py-2 align-middle">{{ customer.name }}</td>
                    </tr>
                </template>
            </VTable>
            <div v-else class="d-flex align-items-center justify-content-center flex-column p-3" style="height: 300px;">
                <i class="fas fa-users-slash" style="font-size: 2rem;" />
                <span class="mt-3">No customers exist yet.</span>
                <small class="mt-2 w-50 text-center">
                    Once your store begins to have customers, they will begin to appear in this section
                </small>
            </div>
        </div>
    </div>
</template>

<script>
    import { getCustomerList } from '../services/CustomerService';
    import LoadingError from '../components/LoadingError';
    import LoadingSpinner from '../components/LoadingSpinner';
    import VTable from '../components/VTable.vue';
    import VTableHeader from '../components/VTableHeader.vue';

    export default {
        name: 'Customers',
        components: {
            LoadingError,
            LoadingSpinner,
            VTable,
            VTableHeader,
        },
        data() {
            return {
                loading: true,
                error: null,
                customers: [],
                search: '',
            };
        },
        async mounted() {
            try {
                const { data } = await getCustomerList();
                this.customers = data;
            } catch (err) {
                this.error = err;
            } finally {
                this.loading = false;
            }
        },
    };
</script>
